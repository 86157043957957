import axios from "axios";
import { API_BASE } from "../env";
import { getAllFinanceAccounts } from "./financeActions";
import { getMembers } from "./memberActions";
import { setSuccess, setErrors, clearErrors, startLoading } from "./uiActions";

export const getAdminDetails = () => async (dispatch) => {
  try {
    // dispatch(getAllBusinesses());
    dispatch(getPendingApprovalBusinesses());
    dispatch(getVerifiedBusinesses());
    dispatch(getAllUsers());
    dispatch(getAllFinanceAccounts());
    dispatch(getMembers());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllBusinesses = () => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING" });

    let businesses = await axios.get(`${API_BASE}/businesses`);
    dispatch({ type: "SET_ALL_BUSINESSES", payload: businesses.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getVerifiedBusinesses = () => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING" });
    let businesses = await axios.get(`${API_BASE}/verified-businesses`);
    dispatch({ type: "SET_VERIFIED_BUSINESSES", payload: businesses.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getPendingApprovalBusinesses = () => async (dispatch) => {
  try {
    let businesses = await axios.get(`${API_BASE}/pending-approval-businesses`);
    dispatch({
      type: "SET_PENDING_APPROVAL_BUSINESSES",
      payload: businesses.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const verifyBusiness = (details, history) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/verify-business`, details);
    dispatch(setSuccess("Successfully verified the business."));
    dispatch(startLoading());
    dispatch(getPendingApprovalBusinesses());
    dispatch(getVerifiedBusinesses());
    history.push("/dashboard/pending-business-table");
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const denyBusiness = (details) => async (dispatch) => {
  try {
    await axios.post(`${API_BASE}/deny-business`, details);
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully denied the business."));
    dispatch(getPendingApprovalBusinesses());
    dispatch(getVerifiedBusinesses());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const addFinanceAccount = (details) => async (dispatch) => {
  console.log("addFinance", details);
  try {
    dispatch(startLoading());
    let business = await axios.post(`${API_BASE}/admin/add-finance-account`, details);
    dispatch({ type: "SET_USERS", payload: business.data });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully added the finance account."));
    // dispatch(getAllBusinesses());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeFinances = (details) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let business = await axios.post(`${API_BASE}/remove-finances`, details);
    dispatch({ type: "SET_USERS", payload: business.data });
    dispatch(clearErrors());
    dispatch(setSuccess("Successfully removed the finance(s)."));

    // dispatch(getAllBusinesses());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: "USERS_DATA_FETCH_STARTED" });

    let users = await axios.get(`${API_BASE}/admin/users`);
    dispatch({ type: "USERS_DATA_FETCH_SUCCESS", payload: users.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      // dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllPayedaTransactions = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING" });

    let transactions = await axios.get(`${API_BASE}/admin/payments`, {
      params: {
        type: params?.type ? params?.type : null,
        date_gt: params?.date_gt ? params?.date_gt : null,
        date_lt: params?.date_lt ? params?.date_lt : null,
      },
    });
    dispatch({ type: "SET_ALL_TRANSACTIONS", payload: transactions.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getUnfinishedPayments = () => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING" });

    let transactions = await axios.get(`${API_BASE}/admin/unfinished-payments`);
    dispatch({ type: "SET_ALL_UNFINISHED_PAYMENTS", payload: transactions.data });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getAllBusinessBankAccounts = ({ ...params }) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING" });

    let transactions = await axios.get(`${API_BASE}/admin/bank-accounts`, {
      params: {
        date_gt: params?.date_gt ? params?.date_gt : null,
        date_lt: params?.date_lt ? params?.date_lt : null,
      },
    });
    dispatch({
      type: "SET_ALL_BUSINESS_BANK_ACCOUNTS",
      payload: transactions.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const getUsersAssignedPackages = () => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING" });

    let transactions = await axios.get(`${API_BASE}/admin/assigned-packages`);
    dispatch({
      type: "SET_ALL_USERS_ASSIGNED_PACKAGES",
      payload: transactions.data,
    });
    dispatch(clearErrors());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};

export const removeAssignedPackagesFromBusiness = (data) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING" });

    let transactions = await axios.post(`${API_BASE}/admin/remove-assigned-packages`, data);
    dispatch(setSuccess("Successfully deleted package from business"));
    dispatch(clearErrors());
    dispatch(getUsersAssignedPackages());
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      dispatch(setErrors(err.response.data));
    } else {
      console.log(err);
      dispatch(setErrors({ general: "Something unexpected happened." }));
    }
  }
};
