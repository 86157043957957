import React, { memo } from "react";
import { Route, useHistory } from "react-router-dom";
import Contacts from "././pages/Contact/Contacts";
import Members from "././pages/Member/Members";

import Customers from "././pages/Customer/Customers";
import Suppliers from "././pages/Supplier/Suppliers";
import LoadingIcon from "./Components/LoadingIcon";
import ProtectedRoute from "./Components/ProtectedRoute";
import ActivityLogs from "./pages/ActivityLogs/ActivityLogs";
import PendingApprovalBusinesses from "./pages/Admin/Business/PendingApprovalBusinesses";
import VerifiedBusinesses from "./pages/Admin/Business/VerifiedBusinesses";
import AddFinance from "./pages/Admin/Finance/AddFinance";
import Finances from "./pages/Admin/Finance/Finances";
import Users from "./pages/Admin/Users/Users";
import CreateBusinessPage from "./pages/Business/CreateBusiness/CreateBusinessPage";
import Plans from "./pages/BusinessPlans/Plans";
import ImportCustomers from "./pages/Customer/ImportCustomers";
import BankAccounts from "./pages/Dashboard/Account/BankAccount/BankAccounts";
import Transactions from "./pages/Dashboard/Account/BankAccount/Transactions";
import BusinessDocuments from "./pages/Dashboard/Account/BusinessDocuments";
import BusinessProfile from "./pages/Dashboard/Account/BusinessProfile/BusinessProfile";
import MergeAccountsPage from "./pages/Dashboard/Account/MergeAccounts/MergeAccountsPage";
import MergedAccountTable from "./pages/Dashboard/Account/MergeAccounts/MergedAccountTable";
import TokenLogs from "./pages/Dashboard/Account/Logs/TokenLogs";
import VerifyAccountPage from "./pages/Dashboard/Account/VerifyAccountPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import CreditRequestedInvoices from "./pages/Finance/Credit/CreditRequestedInvoices";
import FactoringRequestedInvoices from "./pages/Finance/Factoring/FactoringRequestedInvoices";
import FinanceBasketTable from "./pages/Finance/FinanceBasketTable";
import FinanceOffersTable from "./pages/Finance/FinanceOffersTable";
import PendingAgreementApprovalRequests from "./pages/Finance/PendingAgreementApproval/PendingAgreementApprovalRequests";
import SingleFinanceBasketTable from "./pages/Finance/SingleFinanceBasketTable";
import Checkout from "./pages/Invoice/Checkout";
import FinancingOfffersTable from "./pages/Invoice/FinancingOfffersTable";
import FinancingRequestedInvoicesTable from "./pages/Invoice/FinancingRequestedInvoicesTable";
import CreateIncomingInvoice from "./pages/Invoice/Incoming/CreateIncomingInvoice";
import ImportIncomingInvoice from "./pages/Invoice/Incoming/ImportIncomingInvoice";
import IncomingInvoices from "./pages/Invoice/Incoming/IncomingInvoices";
import CloudInvoices from "./pages/Invoice/Outgoing/CloudInvoices";
import CreateOutgoingInvoice from "./pages/Invoice/Outgoing/CreateOutgoingInvoice";
import ImportOutgoingInvoice from "./pages/Invoice/Outgoing/ImportOutgoingInvoice";
import OutgoingInvoices from "./pages/Invoice/Outgoing/OutgoingInvoices";
import Receipts from "./pages/Invoice/Receipt/Receipts";
import ViewReceipt from "./pages/Invoice/Receipt/ViewReceipt";

import InvitationLoginPage from "./pages/LoginSignup/Invitation/InvitationLoginPage";
import SigninPage from "./pages/LoginSignup/SigninPage";
import SignupPage from "./pages/LoginSignup/SignupPage";
import Notifications from "./pages/Notification/Notifications";
import Products from "./pages/Product/Products";
import Settings from "./pages/Settings/Settings";
import ImportSuppliers from "./pages/Supplier/ImportSuppliers";
import TransferMoney from "./pages/TransferMoney/TransferMoney";
import RedirectAISPConsent from "./util/banking/RedirectAISPConsent";
import RedirectCheckoutPISPConsent from "./util/banking/RedirectCheckoutPISPConsent";
import BankingLogs from "./pages/Dashboard/Account/Logs/BankingLogs";
import TimelineComponent from "./pages/HowToUse/TimelineComponent";
import HowItWorks from "./pages/HowToUse/HowItWorks";
import ConnectCloudErp from "./pages/Settings/ConnectCloudErp";
import AllTransactions from "./pages/Admin/Statistics/MoneyRelated/Transactions";
import AllTransactionsByUser from "./pages/Admin/Statistics/MoneyRelated/TransactionsByUser";

import BusinessBankAccounts from "./pages/Admin/Business/BusinessBankAccounts";
import BusinessesAssignedPackages from "./pages/Admin/Business/BusinessesAssignedPackages";
import Packages from "./pages/Admin/Packages/Packages";
import FinancialInformationsTable from "./pages/Settings/FinancialInformationsTable";
import BuyMoreTokens from "./pages/BusinessPlans/BuyMoreTokens";
import UpdateBankingInfo from "./pages/Business/UpdateBankingInfo";
import RequestFinancingPage from "./pages/FinancingDashboard/RequestFinancingPage";
import UnfinishesPayments from "./pages/Admin/Statistics/MoneyRelated/UnfinishedPayments";
import OffersTable from "./pages/FinancingDashboard/OffersTable";

const Routes = (props) => {
  const { history } = useHistory();
  const { user, products, loading, authenticated, businessId, business, businessBankAccounts, isLastPage } = props;
  const { createBusiness, updateBusiness, invitationSignup, invitationSignin, signupUser, signinUser, updateUserInformation } = props;
  const { invitePeople } = props;
  const { addProduct, editProduct, removeProducts } = props;
  const {
    outgoingInvoices,
    createOutgoingInvoice,
    createOutgoingInvoices,
    removeOutgoingInvoices,
    createIncomingInvoices,
    updateStatusOfInvoice,
    outgoingReceipts,
    incomingReceipts,
  } = props;
  const { addSupplierBankAccount, editSupplierBankAccount } = props;
  const { incomingInvoices, createIncomingInvoice, removeIncomingInvoices } = props;
  const {
    updateDocuments,
    addBankAccount,
    makeCheckoutPayment,
    makePayment,

    submitPispConsent,
    removeBankAccount,
    paymentResult,
  } = props;
  const { finances, creditRequestedInvoices, factoringRequestedInvoices, pendingBankApprovalRequests, denyOrApproveRequests, availableInstitutions } = props;
  const { pendingApprovalBusinesses, verifyBusiness, denyBusiness, verifiedBusinesses, allUsers, addFinanceAccount } = props;
  const { mergeAccounts, getInviterInformation, inviterInformation } = props;
  const { getAllProducts, clearProducts } = props;
  const { getCustomers } = props;

  const { notifications, markNotificationAsRead, updatePreferences, activityLogs } = props;
  const { getCompanyHouseInformation, companyHouseResult, isUserPresent, exchangeCodeForCloudToken, tenants, getInvoicesFromCloud, cloudInvoices } = props;

  return loading ? (
    <LoadingIcon />
  ) : (
    <>
      {/* UnAuthenticatedRoutes */}
      {/* <Route
        path="/"
        exact
        render={(props) => (
          <HomePage authenticated={authenticated} {...props} />
        )}
      /> */}
      <Route
        path="/invite"
        render={(props) => (
          <InvitationLoginPage authenticated={authenticated} invitationSignup={invitationSignup} invitationSignin={invitationSignin} loading={loading} {...props} />
        )}
      />
      <Route path="/signup" render={(props) => <SignupPage signupUser={signupUser} loading={loading} authenticated={authenticated} history={history} {...props} />} />
      <Route path="/signin" render={(props) => <SigninPage signinUser={signinUser} loading={loading} authenticated={authenticated} history={history} {...props} />} />
      <Route path="/payment-method" render={(props) => <SigninPage signinUser={signinUser} loading={loading} authenticated={authenticated} history={history} {...props} />} />

      {/* Banking  */}
      <Route path="/redirect-aisp" component={RedirectAISPConsent} />
      <Route
        path="/redirect-checkout-pisp"
        render={(props) => <RedirectCheckoutPISPConsent submitPispConsent={submitPispConsent} paymentResult={paymentResult} authenticated={user.authenticated} {...props} />}
      />
      {/* <Route
        path="/payment-result"
        render={(props) => (
          <PaymentResult authenticated={user.authenticated} {...props} />
        )}
      /> */}

      <Route
        path="/dashboard/checkout"
        render={(props) => <Checkout makeCheckoutPayment={makeCheckoutPayment} availableInstitutions={availableInstitutions} authenticated={user.authenticated} {...props} />}
      />

      {/* Authenticated Routes */}
      <ProtectedRoute
        path="/dashboard"
        exact
        authenticated={user.authenticated}
        loading={loading}
        component={Dashboard}
        user={user}
        business={business}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/verify-account"
        authenticated={user.authenticated}
        loading={loading}
        component={VerifyAccountPage}
        user={user}
        isUserPresent={isUserPresent}
      />

      <ProtectedRoute
        path="/dashboard/create-business"
        authenticated={user.authenticated}
        component={CreateBusinessPage}
        accountType={user.accountType}
        history={history}
        user={user}
        createBusiness={createBusiness}
        updateBusiness={updateBusiness}
        getCompanyHouseInformation={getCompanyHouseInformation}
        companyHouseResult={companyHouseResult}
        isUserPresent={isUserPresent}
        business={business}
      />
      <ProtectedRoute
        path="/dashboard/update-business"
        authenticated={user.authenticated}
        component={CreateBusinessPage}
        accountType={user.accountType}
        history={history}
        user={user}
        createBusiness={createBusiness}
        updateBusiness={updateBusiness}
        getCompanyHouseInformation={getCompanyHouseInformation}
        companyHouseResult={companyHouseResult}
        isUserPresent={isUserPresent}
        business={business}
      />
      <ProtectedRoute
        path="/dashboard/contacts"
        authenticated={user.authenticated}
        component={Contacts}
        business={business}
        businessId={businessId}
        invitePeople={invitePeople}
        isUserPresent={isUserPresent}
        getCustomers={getCustomers}
      />
      <ProtectedRoute
        path="/dashboard/customers"
        authenticated={user.authenticated}
        component={Customers}
        business={business}
        businessId={businessId}
        invitePeople={invitePeople}
        isUserPresent={isUserPresent}
        getCustomers={getCustomers}
      />
      <ProtectedRoute
        path="/dashboard/import-customers"
        authenticated={user.authenticated}
        component={ImportCustomers}
        businessId={businessId}
        business={business}
        history={history}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/create-outgoing-invoice"
        authenticated={user.authenticated}
        component={CreateOutgoingInvoice}
        outgoingInvoices={outgoingInvoices}
        business={business}
        products={products}
        history={history}
        createOutgoingInvoice={createOutgoingInvoice}
        isUserPresent={isUserPresent && user?.permissions?.outgoingInvoice?.create}
      />
      <ProtectedRoute
        path="/dashboard/products"
        authenticated={user.authenticated}
        component={Products}
        business={business}
        products={products}
        addProduct={addProduct}
        editProduct={editProduct}
        removeProducts={removeProducts}
        getAllProducts={getAllProducts}
        clearProducts={clearProducts}
        isLastPage={isLastPage}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/outgoing-invoices"
        authenticated={user.authenticated}
        component={OutgoingInvoices}
        outgoingInvoices={outgoingInvoices}
        removeOutgoingInvoices={removeOutgoingInvoices}
        updateStatusOfInvoice={updateStatusOfInvoice}
        businessId={businessId}
        products={products}
        business={business}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/cloud-invoices"
        authenticated={user.authenticated}
        component={CloudInvoices}
        outgoingInvoices={outgoingInvoices}
        removeOutgoingInvoices={removeOutgoingInvoices}
        updateStatusOfInvoice={updateStatusOfInvoice}
        businessId={businessId}
        products={products}
        business={business}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/suppliers"
        authenticated={user.authenticated}
        component={MergedAccountTable}
        mergeAccounts={mergeAccounts}
        business={business}
        user={user}
        history={history}
        inviterInformation={inviterInformation}
        getInviterInformation={getInviterInformation}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/add-supplier"
        authenticated={user.authenticated}
        component={MergeAccountsPage}
        mergeAccounts={mergeAccounts}
        business={business}
        user={user}
        history={history}
        inviterInformation={inviterInformation}
        getInviterInformation={getInviterInformation}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/import-outgoing-invoice"
        authenticated={authenticated}
        history={history}
        component={ImportOutgoingInvoice}
        createOutgoingInvoices={createOutgoingInvoices}
        exchangeCodeForCloudToken={exchangeCodeForCloudToken}
        getInvoicesFromCloud={getInvoicesFromCloud}
        tenants={tenants}
        cloudInvoices={cloudInvoices}
        business={business}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/import-incoming-invoice"
        authenticated={authenticated}
        history={history}
        component={ImportIncomingInvoice}
        createIncomingInvoices={createIncomingInvoices}
        exchangeCodeForCloudToken={exchangeCodeForCloudToken}
        getInvoicesFromCloud={getInvoicesFromCloud}
        tenants={tenants}
        cloudInvoices={cloudInvoices}
        business={business}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/companies"
        authenticated={user.authenticated}
        component={Suppliers}
        businessId={businessId}
        business={business}
        invitePeople={invitePeople}
        addSupplierBankAccount={addSupplierBankAccount}
        editSupplierBankAccount={editSupplierBankAccount}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/import-suppliers"
        authenticated={user.authenticated}
        component={ImportSuppliers}
        businessId={businessId}
        business={business}
        history={history}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/create-incoming-invoice"
        authenticated={user.authenticated}
        component={CreateIncomingInvoice}
        products={products}
        business={business}
        history={history}
        createIncomingInvoice={createIncomingInvoice}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/incoming-invoices"
        authenticated={user.authenticated}
        component={IncomingInvoices}
        incomingInvoices={incomingInvoices}
        removeIncomingInvoices={removeIncomingInvoices}
        updateStatusOfInvoice={updateStatusOfInvoice}
        business={business}
        businessId={businessId}
        products={products}
        finances={finances}
        user={user}
        availableInstitutions={availableInstitutions}
        makePayment={makePayment}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/my-profile"
        authenticated={user.authenticated}
        component={BusinessProfile}
        business={business}
        businessId={businessId}
        user={user}
        updateUserInformation={updateUserInformation}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/my-documents"
        authenticated={user.authenticated}
        component={BusinessDocuments}
        business={business}
        businessId={businessId}
        user={user}
        updateDocuments={updateDocuments}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/bank-accounts"
        authenticated={user.authenticated}
        component={BankAccounts}
        accounts={businessBankAccounts}
        business={business}
        user={user}
        history={history}
        addBankAccount={addBankAccount}
        removeBankAccount={removeBankAccount}
        availableInstitutions={availableInstitutions}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/transactions"
        authenticated={user.authenticated}
        component={Transactions}
        business={business}
        user={user}
        history={history}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/credit-requests"
        authenticated={user.authenticated}
        component={CreditRequestedInvoices}
        business={business}
        user={user}
        creditRequestedInvoices={creditRequestedInvoices}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/factoring-requests"
        authenticated={user.authenticated}
        component={FactoringRequestedInvoices}
        business={business}
        user={user}
        factoringRequestedInvoices={factoringRequestedInvoices}
        isUserPresent={isUserPresent}
        history={history}
      />
      <ProtectedRoute
        path="/dashboard/pending-approval-agreement"
        authenticated={user.authenticated}
        component={PendingAgreementApprovalRequests}
        business={business}
        user={user}
        pendingBankApprovalRequests={pendingBankApprovalRequests}
        denyOrApproveRequests={denyOrApproveRequests}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/pending-business-table"
        authenticated={user.authenticated}
        component={PendingApprovalBusinesses}
        business={business}
        user={user}
        history={history}
        pendingApprovalBusinesses={pendingApprovalBusinesses}
        verifyBusiness={verifyBusiness}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/verified-business-table"
        authenticated={user.authenticated}
        component={VerifiedBusinesses}
        business={business}
        user={user}
        verifiedBusinesses={verifiedBusinesses}
        denyBusiness={denyBusiness}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/businesses-packages"
        authenticated={user.authenticated}
        component={BusinessesAssignedPackages}
        business={business}
        user={user}
        verifiedBusinesses={verifiedBusinesses}
        denyBusiness={denyBusiness}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/user-accounts"
        authenticated={user.authenticated}
        component={Users}
        business={business}
        user={user}
        allUsers={allUsers}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/add-finance-account"
        authenticated={user.authenticated}
        component={AddFinance}
        business={business}
        user={user}
        addFinanceAccount={addFinanceAccount}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/finances"
        authenticated={user.authenticated}
        component={Finances}
        business={business}
        user={user}
        allFinances={finances}
        isUserPresent={isUserPresent}
      />

      <ProtectedRoute
        path="/dashboard/how-to-use"
        authenticated={user.authenticated}
        component={TimelineComponent}
        business={business}
        user={user}
        allFinances={finances}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute path="/dashboard/members" authenticated={user.authenticated} component={Members} business={business} user={user} />
      <ProtectedRoute
        path="/dashboard/notifications"
        authenticated={user.authenticated}
        component={Notifications}
        notifications={notifications}
        markNotificationAsRead={markNotificationAsRead}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/settings"
        authenticated={user.authenticated}
        component={Settings}
        business={business}
        updatePreferences={updatePreferences}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/connect-erp-cloud-app"
        authenticated={user.authenticated}
        component={ConnectCloudErp}
        business={business}
        updatePreferences={updatePreferences}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute path="/dashboard/activity-logs" authenticated={user.authenticated} component={ActivityLogs} activityLogs={activityLogs} isUserPresent={isUserPresent} />
      <ProtectedRoute
        path="/dashboard/outgoing-receipts"
        authenticated={user.authenticated}
        component={Receipts}
        business={business}
        receipts={outgoingReceipts}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute
        path="/dashboard/incoming-receipts"
        authenticated={user.authenticated}
        component={Receipts}
        business={business}
        receipts={incomingReceipts}
        isUserPresent={isUserPresent}
      />

      <ProtectedRoute path="/dashboard/request-baskets" authenticated={user.authenticated} component={FinanceBasketTable} business={business} isUserPresent={isUserPresent} />
      <ProtectedRoute
        path="/dashboard/finance-basket/:id"
        authenticated={user.authenticated}
        component={SingleFinanceBasketTable}
        business={business}
        isUserPresent={isUserPresent}
      />
      <ProtectedRoute path="/dashboard/finance-offers" authenticated={user.authenticated} component={FinanceOffersTable} business={business} isUserPresent={isUserPresent} />

      <ProtectedRoute
        path="/dashboard/financing-invoices"
        authenticated={user.authenticated}
        component={FinancingRequestedInvoicesTable}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
      />

      <ProtectedRoute
        path="/dashboard/financing-offers"
        authenticated={user.authenticated}
        component={OffersTable}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
      />

      <ProtectedRoute path="/dashboard/buy-plans" authenticated={user.authenticated} component={Plans} business={business} isUserPresent={isUserPresent} history={history} />
      <ProtectedRoute
        path="/dashboard/upgrade-plan"
        authenticated={user.authenticated}
        component={BuyMoreTokens}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
      />

      <ProtectedRoute
        path="/dashboard/transfer-money"
        authenticated={user.authenticated}
        component={TransferMoney}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
      />
      <ProtectedRoute path="/dashboard/token-logs" authenticated={user.authenticated} component={TokenLogs} business={business} isUserPresent={isUserPresent} history={history} />
      <ProtectedRoute
        path="/dashboard/banking-logs"
        authenticated={user.authenticated}
        component={BankingLogs}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
      />
      <ProtectedRoute
        path="/dashboard/how-it-works"
        authenticated={user.authenticated}
        component={HowItWorks}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />
      <ProtectedRoute
        path="/dashboard/all-transactions"
        authenticated={user.authenticated}
        component={AllTransactions}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />
      <ProtectedRoute
        path="/dashboard/all-transactions-by-user"
        authenticated={user.authenticated}
        component={AllTransactionsByUser}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />
      <ProtectedRoute
        path="/dashboard/incomplete-payments"
        authenticated={user.authenticated}
        component={UnfinishesPayments}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />
      <ProtectedRoute
        path="/dashboard/business-bank-accounts"
        authenticated={user.authenticated}
        component={BusinessBankAccounts}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />

      <ProtectedRoute
        path="/dashboard/packages"
        authenticated={user.authenticated}
        component={Packages}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />

      <ProtectedRoute
        path="/dashboard/update-banking"
        authenticated={user.authenticated}
        component={UpdateBankingInfo}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />
      <ProtectedRoute
        path="/dashboard/financial-informations"
        authenticated={user.authenticated}
        component={FinancialInformationsTable}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />

      <ProtectedRoute
        path="/dashboard/request-financing"
        authenticated={user.authenticated}
        component={RequestFinancingPage}
        business={business}
        isUserPresent={isUserPresent}
        history={history}
        user={user}
      />
    </>
  );
};

export default memo(Routes);
