const initialState = {
  businessDetails: [],
  userDetails: [],
  verifiedBusinesses: [],
  pendingApprovalBusinesses: [],
  isLoading: false,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_BUSINESSES":
      return {
        ...state,
        businessDetails: action.payload,
      };
    case "VERIFIED_BUSINESSES_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_VERIFIED_BUSINESSES":
      return {
        ...state,
        isLoading: false,
        verifiedBusinesses: action.payload,
      };
    case "SET_PENDING_APPROVAL_BUSINESSES":
      return {
        ...state,
        pendingApprovalBusinesses: action.payload,
      };
    case "SET_USERS":
      return {
        ...state,
        userDetails: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_ALL_TRANSACTIONS":
      return {
        ...state,
        isLoading: false,
        allTransactions: action.payload,
      };
    case "SET_ALL_UNFINISHED_PAYMENTS":
      return {
        ...state,
        isLoading: false,
        unfinishedPayments: action.payload,
      };
    case "SET_ALL_BUSINESS_BANK_ACCOUNTS":
      return {
        ...state,
        isLoading: false,
        allBankAccounts: action.payload,
      };
    case "SET_ALL_USERS_ASSIGNED_PACKAGES":
      return {
        ...state,
        isLoading: false,
        allUsersWithAssignedPackages: action.payload,
      };
    case "USERS_DATA_FETCH_STARTED":
      return {
        ...state,
        isLoading: true,
      };
    case "USERS_DATA_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
